
    <mat-card-title class="mb-5">
        <h5>
            <span class="fw-light" [routerLink]="['/']">aaron</span>
            <fa-icon [fixedWidth]="true" transform="shrink-2" [icon]="chevron"></fa-icon>
            husband
        </h5>
    </mat-card-title>

    <mat-card-content class="mb-5">
        <div class="w-100 mb-3">
            <h4>{{formattedDate(meeting)}} – {{formattedDate(today)}}</h4>
            {{formattedDuration(lifetimeDuration)}} since our paths first truly crossed
        </div>
        <canvas #chart></canvas>
    </mat-card-content>

    <mat-card-content>
        <div style="padding:56.25% 0 0 0;position:relative;">
            <iframe src="https://player.vimeo.com/video/96077716?portrait=0"
                style="position:absolute;top:0;left:0;width:100%;height:100%;"
                frameborder="0"
                allow="fullscreen; picture-in-picture"
                allowfullscreen>
            </iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
    </mat-card-content>
