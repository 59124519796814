import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'aaron-opener-info',
    templateUrl: './opener-info.component.html',
    styleUrls: ['./opener-info.component.scss']
})
export class OpenerInfoComponent {

    isToggled = false;
    @Output() toggled = new EventEmitter<boolean>();

    externalLink(path: string): void {
        window.location.href = path;
    }
    toggle(): void {
        this.isToggled = !this.isToggled;
        this.toggled.emit(this.isToggled);
    }

}
