<mat-card-content>
    <div class="ps-2 pb-2 float-end">
        <button mat-fab mat-card-avatar
            (click)="toggle();"
            class="float-end align-self-baseline w-auto h-auto p-0 rounded-circle bitmoji mat-elevation-z0"
            style="max-width:6rem;"
            data-toggle="collapse"
            href="#collapseQuotes"
            aria-expanded="false"
            aria-controls="collapseQuotes">
            <img src="https://www.gravatar.com/avatar/6c8d59ba2e1fb66eed68d61feb5df41e?s=150" class="w-100 rounded-circle" >
        </button>
    </div>
    <p class="fs-1">
        <span class="fw-light">aaron</span> yarhouse
    </p>
    <p class="titles fs-5 text-muted">
        <span><a [routerLink]="['stats']">husband </a></span>
        <span>father </span>
        <span><a href="https://allogy.com"
            target="_blank"
            rel="noopener">developer </a></span>
        <span><a href="https://www.dndbeyond.com/profile/yarhouse/characters/6561029"
            target="_blank"
            rel="noopener">table-top gamer </a></span>
        <span>captain america fanboy </span>
        <span>usually in that order </span>
    </p>
</mat-card-content>
<mat-card-content class="mt-3">
    <p class="fw-light"><span class="fw-bold">i</span>'ve been in <span class="fw-bold">love</span> with the forward-facing <span class="fw-bold">web</span> since mom bought our first computer in 2000. endless nights were spent crafting angelfire or geocities websites for my friends bands or writing custom style blocks for livejournals and myspace pages.</p>
    <p class="fw-light">now my eudaimonia comes from the work i do creating user interfaces for learning management systems and data interpretations, being a loving and supportive spouse and parent to my amazing family, and occasionally playing dungeons & dragons with my friends.</p>
</mat-card-content>
<hr/>
