
<div class="overflow-hidden" *ngIf="showWords" @animateCollapse>
    <div class="my-4">
        <mat-card-content>
            <iframe style="width:100%;border-radius:25px" src="https://open.spotify.com/embed/track/2apH3VNdIJh5xyCeHTQMF5?utm_source=generator&theme=0" height="80" frameBorder="0" allowfullscreen="false" allow="encrypted-media"></iframe>
        </mat-card-content>
        <mat-card-content>
            <hr/>
            <figure class="">
                <blockquote class="mb-4">
                    <p class="fw-light">Let not any one pacify his conscience by the delusion that he can do no harm if he takes no part, and forms no opinion. <span class="fw-bold">Bad men need nothing more to compass their ends, than that good men should look on and do nothing.</span> He is not a good man who, without a protest, allows wrong to be committed in his name, and with the means which he helps to supply, because he will not trouble himself to use his mind on the subject.</p>
                </blockquote>
                <figcaption class="blockquote-footer">
                    John Stuart Mill<br /> from the <cite>Inaugural Address to the University of St. Andrews, Feb. 1st 1867</cite>
                </figcaption>
            </figure>
            <hr/>
            <figure class="">
                <blockquote class="mb-4">
                    <p class="fw-light"><span class="fw-bold">If you are neutral in situations of injustice, you have chosen the side of the oppressor.</span> If an elephant has its foot on the tail of a mouse and you say that you are neutral, the mouse will not appreciate your neutrality.</p>
                </blockquote>
                <figcaption class="blockquote-footer">
                    The Most Reverend Desmond Tutu<br/> in <cite>"Ending Poverty As We Know It: Guaranteeing A Right To A Job" (2003)</cite><br/> by William P. Quigley, p. 8
                </figcaption>
            </figure>
            <hr/>
        </mat-card-content>
    </div>
</div>
