import { animate, style, transition, trigger } from '@angular/animations';
import { environment } from 'src/environments/environment';

export const AnimateCollapse = trigger('animateCollapse', [
    transition(':enter', [
        style({ height: '0px' }),  // initial
        animate(`${environment.ANIMATION_MILLISECONDS}ms ease-in-out`,
            style({ height: '*' }))  // final
    ]),
    transition(':leave', [
        style({ height: '*' }),  // initial
        animate(`${environment.ANIMATION_MILLISECONDS}ms ease-in-out`,
            style({ height: '0px' }))  // final
    ]),
]);

