import { Component } from '@angular/core';

@Component({
    selector: 'aaron-root',
    template: `<router-outlet></router-outlet>`,
    styles: [`
        :host {
            width:100vw;
            height: 100vh;
        }`
    ]
})
export class AppComponent {}
