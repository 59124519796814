import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { HomeComponent } from './components/home/home.component';
import { StatisticsComponent } from './components/statistics/statistics.component';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                component: DashboardComponent,
            },
            {
                path: 'stats',
                component: StatisticsComponent
            },
        ]
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: true,
            // enableTracing: true, // <-- debugging purposes only
            // onSameUrlNavigation: 'reload'
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
