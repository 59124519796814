import { Component, ElementRef, OnInit } from '@angular/core';

@Component({
    selector: 'aaron-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

    showWords!: boolean;
    constructor(
        private elementRef: ElementRef,
    ) {
        this.elementRef.nativeElement.classList.add('d-flex', 'flex-column', 'flex-fill');
    }

    ngOnInit(): void {

    }

}
