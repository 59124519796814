<mat-card-content>
    <div class="fs-4 mb-3">
        <span class="fw-light">my</span> work
    </div>
    <div class="row">
        <div class="col-12 d-flex flex-column align-items-start">
            <!-- <div class="fs-5 my-3 fw-bold">currently</div> -->
            <a href="https://training.allogy.com/"
                class="text-body text-decoration-none text-truncate text-start"
                target="_blank"
                rel="noopener" >
                <span class="fs-6">capillary lms</span>
            </a>
            <div>
                <small class="text-secondary">a globally distributed LMS and education analytics system to improve training outcomes</small>
            </div>
            <hr class="w-50"/>
            <a href="https://deployedmedicine.com/"
                class="text-body text-decoration-none text-truncate text-start"
                target="_blank"
                rel="noopener" >
                <span class="fs-6">deployed medicine</span>
            </a>
            <div>
                <small class="text-secondary">DHS project supported by JTS and CoTCCC to continuously train combat medics and beyond</small>
            </div>
            <hr class="w-50"/>
            <a href="https://bonsai.allogy.com/"
                class="text-body text-decoration-none text-truncate text-start"
                target="_blank"
                rel="noopener" >
                <span class="fs-6">bonsai learning</span>
            </a>
            <div>
                <small class="text-secondary">education platform for continuous learning</small>
            </div>
        </div>
        <div class="col-12 d-flex flex-column align-items-end text-end text-muted">
            <div class="fs-5 mb-3 mt-5 mt-3 fw-light">previously</div>
            <a href="https://krondorkrew.com/"
                class="text-muted text-decoration-none text-truncate text-end"
                target="_blank"
                rel="noopener" >
                <span class="fs-6">krondor krew</span>
            </a>
            <div>
                <small class="text-muted">defunct nerdcore rap group, once the greatest in ninja hip-hop</small>
            </div>
            <hr class="w-50"/>
            <a href="http://www.rainbowsofaging.org/"
                class="text-muted text-decoration-none text-truncate text-end"
                target="_blank"
                rel="noopener" >
                <span class="fs-6">rainbows of aging</span>
            </a>
            <div>
                <small class="text-muted">PHD led research into the aging LGBTQI+ community and their health needs</small>
            </div>
            <hr class="w-50"/>
            <a href="http://carlotaworldwide.com/"
                class="text-muted text-decoration-none text-truncate text-end"
                target="_blank"
                rel="noopener" >
                <span class="fs-6">carlota z., j.d.</span>
            </a>
            <div>
                <small class="text-muted">personal &amp; professional development coach</small>
            </div>
        </div>
    </div>
</mat-card-content>
