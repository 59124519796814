import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Chart, { ChartConfiguration, ChartData } from 'chart.js/auto';
import { differenceInSeconds, formatDuration } from 'date-fns';
import format from 'date-fns/format';
import intervalToDuration from 'date-fns/intervalToDuration';


@Component({
    selector: 'aaron-statistics',
    templateUrl: './statistics.component.html',
    styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit, AfterViewInit {

    constructor() { }
    @ViewChild('chart') chartRef!: ElementRef;
    @Input() data!: ChartData<'doughnut', number[], string>;

    dating = new Date(2009, 1, 20, 12, 0, 0);
    engaged = new Date(2010, 11, 22, 12, 0, 0);
    marriage = new Date(2013, 10, 23, 12, 0, 0);
    meeting = new Date(2003, 10, 23, 12, 0, 0);
    today = new Date();
    datingSec = differenceInSeconds(
        this.dating,
        this.engaged
    );
    engagedSec = differenceInSeconds(
        this.engaged,
        this.marriage
    );
    friendSec = differenceInSeconds(
        this.meeting,
        this.dating
    );
    lifetimeDuration = intervalToDuration({
        start: this.meeting,
        end: this.today
    });
    marriedSec = differenceInSeconds(
        this.marriage,
        this.today
    );
    chevron = faChevronLeft;

    private chartConfig: ChartConfiguration = {
        type: 'doughnut',
        data: {
            labels: [
                'Friends',
                'Dating',
                'Engaged',
                'Married',
            ],
            datasets: [{
                label: 'Our Time',
                data: [
                    this.friendSec,
                    this.datingSec,
                    this.engagedSec,
                    this.marriedSec,
                ],
                backgroundColor: [
                    'rgb(255, 99, 132)',
                    'rgb(54, 162, 235)',
                    'rgb(154, 162, 235)',
                    'rgb(255, 205, 86)'
                ],
                hoverOffset: 4
            }]
        },
        options: {
            plugins: {
                tooltip: {
                    callbacks: {
                        label: (context) => {
                            let label = context.label || '';
                            if (label) {
                                label += ' for ';
                            }
                            if (context.parsed !== null) {
                                label += formatDuration(
                                    intervalToDuration({ start: 0, end: (context.parsed * 1) * 1000 }),
                                    {
                                        delimiter: ', ',
                                        format: ['years', 'months', 'days']
                                    });
                            }
                            return label;
                        }
                    }
                }
            }
        }
    };
    private chart!: Chart;

    ngOnInit(): void {
        // this.calculateDates();
    }

    ngAfterViewInit(): void {
        this.chart = new Chart(
            this.chartRef.nativeElement,
            this.chartConfig,
        );
    }

    formattedDate(date: Date): string {
        return format(date, 'MMM dd, yyyy');
    }

    formattedDuration(duration: Duration): string {
        return formatDuration(
            duration,
            {
                delimiter: ', ',
                format: ['years', 'months', 'days']
            });
    }
}
