import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MaterialModule } from './angular-material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DevProjectsComponent } from './components/dashboard/dev-projects/dev-projects.component';
import { OpenerInfoComponent } from './components/dashboard/opener-info/opener-info.component';
import { SocialLinksComponent } from './components/dashboard/social-links/social-links.component';
import { SpecialTextComponent } from './components/dashboard/special-text/special-text.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { HomeComponent } from './components/home/home.component';


@NgModule({
    declarations: [
        AppComponent,
        DashboardComponent,
        OpenerInfoComponent,
        DevProjectsComponent,
        SpecialTextComponent,
        SocialLinksComponent,
        StatisticsComponent,
        HomeComponent
    ],
    imports: [
        RouterModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FontAwesomeModule,
        MaterialModule,
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {}
