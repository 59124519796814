<hr/>
<mat-card-content class="d-flex justify-content-evenly pt-2">
    <a mat-icon-button
        matTooltip="linkedin"
        class="text-truncate text-center fs-2"
        target="_blank"
        rel="noopener"
        href="https://www.linkedin.com/in/aaronyarhouse">
        <fa-icon class="d-block" [fixedWidth]="true" [icon]="linkedIn"></fa-icon>
        <!-- linkedin -->
    </a>
    <a mat-icon-button
        matTooltip="github"
        class="text-truncate text-center fs-2"
        target="_blank"
        rel="noopener"
        href="https://github.com/yarhouse">
        <fa-icon class="d-block" [fixedWidth]="true" [icon]="github"></fa-icon>
        <!-- github -->
    </a>
</mat-card-content>
