import { Component, Input } from '@angular/core';
import { AnimateCollapse } from 'src/app/app-animations';

@Component({
    selector: 'aaron-special-text',
    templateUrl: './special-text.component.html',
    styleUrls: ['./special-text.component.scss'],
    animations: [AnimateCollapse]
})
export class SpecialTextComponent {
    @Input() showWords = false;
}
